import { isMobile } from '@/utils/common'

export const appWidth = isMobile() ? '100%': '600px'

export const maxMessageContentWidth = 300
export const maxMessageContentHeight = 300

export const appInfo = {
  name: '缘起聊天',
  site: 'yuanqilt.com',
  titleSuffix: ' - 缘起聊天 | yuanqilt.com'
 }